.contact{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    background-color: rgb(243, 240, 240);
    flex-wrap: wrap;
    padding: 2vmax 1vmax;
    margin: auto;
}
.contact_left{
    width: 50%;
    background-color: white;
    padding: 1vmax;
    margin-right: 1vmax;
}
.name{
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 2vmax 0vmax;
}
.name_label{
    width: 47%;
    margin-right: 1vmax;
}
.name_label p{
    margin-bottom: 0px;
    font-weight: bold;
}
.name_label input{
    border: none;
    outline: none;
    background-color: rgb(227, 225, 225);
    padding: 0.5vmax 1vmax;
    width: 100%;
    
}
.name_label_txt textarea{
    border: none;
    outline: none;
    width: 96%;
    height: 100px;
    margin-bottom: 1vmax;
    padding: 0.5vmax 1vmax;
    background-color: rgb(227, 225, 225);
}
#msg{
    margin-bottom: 0px;
    font-weight: bold;
}
.message_btn{
    background-color: #f5ab35;
    padding: 1vmax 2vmax;
    color: white;
    font-weight: bold;
    border: none;
    transition: 0.5s;
}
.message_btn:hover{
    background-color: rgb(117, 116, 116);
}

.contact_right{
    width: 30%;
    background-color: white;
    padding: 1vmax;
    
}
.contact_right .image{
    border-radius: 50%;
    margin: auto;
    width: 200px;
    height: 200px;
    padding: 0.5vmax;
    background-color: rgb(148, 146, 146);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vmax;
}
.contact_right img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* text-align: center; */
    /* margin-bottom: 2vmax; */
}
#title{
    text-align: center;
}
#desc{
    text-align: center;
}
#icons_link{
    margin-right: 1vmax;
    color: black;
    
    transition: 0.5s;
}
#icons_link:hover{
    color: blue;
}



@media screen and (max-width:1200px) {
    .contact{
        display: block;
        padding: 0px;
        width: 100%;
        margin: auto;
    }
    .contact_left{
        width: 95%;
    }
    .name{
        display: block;
    }
    .name_label{
        width: 96%;
    }
    .name_label input{
        padding: 1vmax 1vmax;
        font-size: 2.5vmax;
    }
    .contact_right{
        width: 95%;
        margin-top: 2vmax;
    }
}