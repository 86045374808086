*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

[data-theme="light"]{
  --bg-color:#ffffff;
  --text-color:#333333;
  --heading-color:#444444;
}
[data-theme="dark"]{
  --bg-color:#333333;
  --text-color:#ffffff;
  --heading-color:#dddddd;
}

body{
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}
.latest_news_link{
  color: var(--text-color) !important;
}