.dashboard{
    width: 100%;
    min-height: 60vh;
}
.dashboard_header_box{
    width: 100%;
    background-color: #6528F7;
    padding: 0.5vmax 0vmax;
}
.dashboard_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    color: white;
    margin: auto;
    font-weight: bold;
}
.dashboard_link{
    text-decoration: none;
    font-size: 20px;
    color: white;
}
.dashboard_header p{
    margin-bottom: 0px;
    cursor: pointer;
}
.dasboard_home{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding: 1vmax;
}
.dasboard_home_sidebar{
    width: 20%;
    /* border-right: 1px solid rgb(144, 144, 144); */
    
    margin: 1vmax;
}
.dashboard_post{
    width: 90%;
}

.total{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vmax;
    margin: 0.5vmax;
    width: 100%;
    /* background-color: red; */
}
.box{
    width: 22%;
    height: 150px;
    margin: 1vmax;
    border-radius: 0.5vmax;
    text-align: center;
    padding: 0.5vmax 0;
    color: white;
}
.total_post{
    background-color: green;
}
.total_users{
    background-color: rgb(211, 54, 54);
}
.total_message{
    background-color: rgb(171, 22, 171);
}
.total_graph{
    /* background-color: rgb(92, 142, 92); */
    width: 15%;
}