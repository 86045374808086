.post_category{
    width: 80%;
    padding-top: 4vmax;
    margin: auto;
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
    /* background-color: aqua; */
    flex-wrap: wrap;
}
.post_category_left{
    /* background-color: aqua; */
    width: 75%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-wrap: wrap;
}
.news_details_left_related_link{
    margin: 1vmax;
    width: 30%;
}
.post_category_right{
    width: 25%;
    padding-left: 2vmax;
}


@media screen and (max-width:1200px) {
    .post_category{
        flex-direction: column-reverse;
        width: 100%;
    }
    .post_category_left{
        width: 100%;
    }
    .news_details_left_related_link{
        width: 95%;
    }
    
    .post_category_right{
        width: 95%;
    }
    #related_news{
        width: 100%;
    }
    .latest_news_link{
        width: 98%;
    }
}