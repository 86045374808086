.news_container{
    width: 70%;
    margin-top: 2vmax !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
    /* background-color: aqua; */
}
.news_container_link{
    width: 31%;
    text-decoration: none;
    margin: 0.5vmax;
}
#home_img{
    width: 60%;
    height: 400px;
}
#news_card_img{
    width: 100%;
    height: 200px;
}

@media screen and (max-width:1200px) {
    .news_container{
        width: 95%;
    }
    .news_container_link{
        width: 90%;
    }
}