.about{
    display: flex;
    justify-content: center;
    margin: auto;
    margin: 3vmax 0;
    padding:0 2vmax;
    flex-wrap: wrap;
}
#about{
    text-align: center;
    font-size: 1.5vmax;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 2vmax;
}
.about_left{
    width: 30%;
    align-items: center;
    justify-content: center;
    padding-right: 2vmax;
    
}
.about_left .image{
    margin: auto;
    padding: 5px;
    background-color: rgb(154, 151, 151);
    border-radius: 50%;
    height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vmax;
}
.about_left img{
    border-radius: 50%;
    width: 100%;
}
.about_right{
    width: 50%;
    padding-left: 2vmax;
}
#desc_about{
    float: left !important;
    text-align: center;
}


@media screen and (max-width:1200px) {
    .about{
        display: block;
    }
    .about_left{
        width: 100%;
        margin-bottom: 2vmax;
    }
    .about_right{
        width: 90%;
        padding: 1vmax;
        margin: auto;
    }
}