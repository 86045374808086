.login_container {
    width: 100%;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(21,124,194,1) 0%, rgba(6,73,150,1) 100%); */
    background-color: #330033;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23330055' cx='50' cy='0' r='50'/%3E%3Cg fill='%233a015d' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23410165' cx='50' cy='100' r='50'/%3E%3Cg fill='%2348026e' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23500376' cx='50' cy='200' r='50'/%3E%3Cg fill='%2357047e' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%235f0587' cx='50' cy='300' r='50'/%3E%3Cg fill='%2367068f' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%236f0798' cx='50' cy='400' r='50'/%3E%3Cg fill='%237707a0' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%238008a9' cx='50' cy='500' r='50'/%3E%3Cg fill='%238909b1' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%239109ba' cx='50' cy='600' r='50'/%3E%3Cg fill='%239a09c3' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a309cb' cx='50' cy='700' r='50'/%3E%3Cg fill='%23ad09d4' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b608dc' cx='50' cy='800' r='50'/%3E%3Cg fill='%23c007e5' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c905ee' cx='50' cy='900' r='50'/%3E%3Cg fill='%23d303f6' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23D0F' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
}
#log_title{
    font-size: 2rem;
    margin-bottom: 5vmax;
    text-align: center;
}
.login_box {
    width: 30%;
    /* background-color: #330055; */
    color: black;
    background-color: #fff;
    border-radius: 1vmax;
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23330055' cx='50' cy='0' r='50'/%3E%3Cg fill='%233a015d' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23410165' cx='50' cy='100' r='50'/%3E%3Cg fill='%2348026e' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23500376' cx='50' cy='200' r='50'/%3E%3Cg fill='%2357047e' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%235f0587' cx='50' cy='300' r='50'/%3E%3Cg fill='%2367068f' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%236f0798' cx='50' cy='400' r='50'/%3E%3Cg fill='%237707a0' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%238008a9' cx='50' cy='500' r='50'/%3E%3Cg fill='%238909b1' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%239109ba' cx='50' cy='600' r='50'/%3E%3Cg fill='%239a09c3' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a309cb' cx='50' cy='700' r='50'/%3E%3Cg fill='%23ad09d4' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b608dc' cx='50' cy='800' r='50'/%3E%3Cg fill='%23c007e5' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c905ee' cx='50' cy='900' r='50'/%3E%3Cg fill='%23d303f6' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23D0F' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E"); */
    /* background-attachment: fixed; */
    background-size: contain;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

}
#login_form{
    width: 70%;
    margin: auto;
}
.input_avt{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(72, 71, 71);
    margin-bottom:1.5vmax;
}
#login_link {
    /* color: white !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2vmax;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 0.5vmax 2vmax;
    border: none;
    color: rgb(94, 94, 245);
    font-weight: bold;
    transition: 0.3s;
}
#login_link:hover {
    background-color: rgb(94, 94, 245);
    color: white !important;
}
#forget{
    float: right;
    margin-top: -1.5vmax;
}

.username input {
    width: 100%;
    background-color: transparent;
    border: none;
    /* border-bottom: 1px solid rgb(72, 71, 71); */
    font-size: 1.5vmax;
    margin: -1vmax 0;
    padding: 1vmax 0.5vmax;
}

.username input:focus {
    outline: 0;
    background-color: transparent;
}

.password input {
    width: 100%;
    background-color: transparent;
    border: none;
    /* border-bottom: 1px solid rgb(72, 71, 71); */
    font-size: 1.5vmax;
    margin: -1vmax 0;
    padding: 1vmax 0.5vmax;
}

.password input:focus {
    outline: 0;
}

.avatar,
.password {
    /* color: rgb(94, 94, 245); */
}

.btn_submit {
    width: 100%;
    margin-top: 1vmax;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 0.5vmax 2vmax;
    border: none;
    color: rgb(94, 94, 245);
    font-weight: bold;
    transition: 0.3s;

}

.btn_submit:hover {
    background-color: rgb(94, 94, 245);
    color: white;
}

#verify{
    text-align: center;
    margin-top: 1vmax;
    width: 100%;
    padding: 0.5vmax;
    font-size: 1.3vmax;
    background-color: rgb(24, 134, 24);
    color: white;
}
.verify-page{
    width: 40%;
    margin: auto ;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}
.verify-page p{
    font-weight: bold;
    font-size: 2vmax;
}
#tick{
    color: green;
    font-size: 12vmax;
    width: 100%;
    text-align: center;
}
#veri-login{
    text-align: center;
    width: 20%;
    margin: auto;
    text-decoration: none;
    background-color: rgb(45, 129, 45);
    color: white;
    padding: 0.3vmax 2vmax;
    border-radius: 1vmax;
}

@media screen and (max-width:1200px) {
    .login_box {
        width: 90%;
        padding: 2vmax;
    }
    #login_form{
        width: 100%;
    }
    .username  input,.password input {
        font-size: 5vmax;
        /* margin: -1vmax 0; */
        padding: 1vmax 0.5vmax;
    }
    form{
        width: 90%;
    }
    #verify{
        margin-top: 2vmax;
        padding: 0.5vmax;
        font-size: 3vmax;
        background-color: rgb(24, 134, 24);
        color: white;
    }
}