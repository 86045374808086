.footer_container{
    background-color: #070b51;
    color: white;
    padding: 1vmax 0vmax;
    margin-top: 1vmax;
    top: 20vh;
    /* min-height: 90vh; */
    position: relative;
}
.footer{
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    flex-wrap: wrap;
    
}
.footer_box{
    width: 24%;
    margin: 0.5vmax;
}
.footer_box img{
    width: 60px;
    height:60px;
    border-radius: 50%;
    margin-bottom: 1vmax;
}
#imp_link{
    font-weight: bold;
    font-size: 1.5vmax;
}
#link{
    text-decoration: none;
    transition: 0.5s;
    color: white;
    margin-right: 1vmax;
}
#link:hover{
    color: rgb(75, 75, 248);
}
#reserved{
    text-align: center;
    margin-top: 1vmax;
}


@media screen and (max-width:1200px) {
    .footer{
        display: block;
    }
    .footer_box{
        width: 90%;
        text-align: center;
    }
    #imp_link{
        font-size: 3vmax;
    }
}
